import React from "react";
import SEO from "@components/seo";
import Layout from "@components/layout";
import Footer from "@components/footers/dark";
import MenuBar from "@components/menuBar/MenuBar";
import Bios from "../Bios.js";
import get from "lodash/get";

class TeamBios extends React.Component {
  constructor(props) {
    super(props);

    this.member = {
      info: get(this, 'props.data.allContentfulTeamMember.nodes'),
    }
  }

  render() {
    return (
      <>
        <SEO title="Team" />
        <MenuBar/>
        <Layout>
          <Bios heading="The Team" people={this.member.info}/>
        </Layout>
        <Footer/>
      </>
    )
  }
}
export default TeamBios

export const pageQuery = graphql`
query {
  allContentfulTeamMember(sort: {order: ASC, fields: order}) {
    nodes {
      name
      title
      bio {
        bio
      }
      photo {
        gatsbyImageData (
          height: 238
        )
      }
    }
  }
}`
